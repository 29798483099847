/** Italian translations. */
export const translations = {

  __localeID: 'it',

  _dateFormat: 'dd/MM/yy',
  _shortDateFormat: 'dd/MM/yy, HH.mm',
  _mediumDateFormat: 'd MMMM yyyy, HH.mm',
  _longDateFormat: 'EEEE, d MMMM yyyy, HH.mm',

  _keyHome: 'Home',
  _keyEnd: 'Fine',
  _keyShift: '⇧ Maiusc',
  _keyAlt: 'Alt',
  _keyControl: '✲ Ctrl',

  pageNotFound: 'La pagina richiesta non è stata trovata.',

  accountApiAuthKey: 'Codice di autorizzazione API',
  accountAuthorities: 'Autorizzazioni',
  accountEmail: 'Indirizzo e-mail',
  accountFirstName: 'Nome',
  accountLanguage: 'Lingua',
  accountLastName: 'Cognome',
  accountPassword: 'Password',
  accountPasswordRetype: 'Ripeti password',
  accountScopes: 'Aree',
  accountTitle: 'Titolo',
  accountTitleMr: 'Sig.',
  accountTitleMs: 'Sig.ra',
  accountUsername: 'Nome utente',

  actions: 'Azioni',
  actionCancel: 'Interrompi',
  actionClose: 'Chiudi',
  actionCollapse: 'Comprimi',
  actionCompare: 'Confronta',
  actionCreate: 'Crea',
  actionDelete: 'Cancella',
  actionDownload: 'Scarica',
  actionDownloadAsZip: 'Scarica come ZIP',
  actionEdit: 'Modifica',
  actionEditHotspots: 'Modifica hotspot',
  actionExpand: 'Espandi',
  actionExportAsExcel: 'Esporta come Excel',
  actionLoad: 'Carica',
  actionLogIn: 'Iscriviti',
  actionLogOut: 'Annulla l’iscrizione',
  actionMove: 'Sposta',
  actionOpenInNewWindow: 'Apri nella nuova finestra',
  actionPageMarkerAdd: 'Aggiungi segnapagina',
  actionPageMarkerRemove: 'Rimuovi segnapagina',
  actionPrint: 'Stampa',
  actionQuantityDecrease: 'Riduci numero',
  actionQuantityIncrease: 'Aumenta numero',
  actionRedo: 'Ripeti',
  actionRelease: 'Pubblica',
  actionRemove: 'Rimuovi',
  actionReplace: 'Sostituisci',
  actionReset: 'Reset',
  actionSave: 'Salva',
  actionSend: 'Invia',
  actionShowAll: 'Mostra tutto',
  actionUndo: 'Annulla',
  actionUnrelease: 'Rimuovi documento pubblicato',
  actionUpload: 'Carica',
  actionView: 'Mostra',

  backgroundSizeCenter: 'Centra',
  backgroundSizeFill: 'Compila',
  backgroundSizeRepeat: 'Ripeti',
  backgroundSizeStretch: 'Estendi',

  bookmarks: 'Segnalibro',
  bookmarksAdded: 'Aggiunta pagina {{page}} ai segnalibri.',
  bookmarksNone: 'Non hai ancora dei segnalibri. Aprire la panoramica della pagina e fare clic sull\'icona del <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>bookmark<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> per aggiungere una pagina ai segnalibri.',
  bookmarksRemoved: 'Pagina {{page}} rimossa dai segnalibri.',

  browserDimensions: 'page2flip non è compatibile con queste dimensioni della finestra. Si prega di modificare le dimensioni della finestra.',
  browserError1: 'Il tuo browser non è attualmente supportato da page2flip.',
  browserError2: 'Si prega di utilizzare uno dei nostri browser supportati.',

  config: 'Conﬁgurazione',
  configAnimateCorners: 'Animare gli angoli',
  configAnimateHotspots: 'Animare gli hotspot',
  configBackgroundImage: 'Sfondo',
  configBackgroundSize: 'Dimensioni sfondo',
  configCornerSize: 'Dimensioni angolo (px)',
  configCoverPage: 'Copertina (solo app)',
  configCurrency: 'Valuta',
  configCustomCSS: 'CSS proprio',
  configCustomJS: 'JavaScript proprietario',
  configDesign: 'Design',
  configDocumentMargin: 'Distanza documento del bordo della finestra (px)',
  configDoublePage: 'Pagine doppie',
  configFeatures: 'Caratteristiche',
  configHideToolbar: 'Nascondi barra degli strumenti',
  configIndexing: 'Indicizzazione motori di ricerca',
  configLayout: 'Layout',
  configLoader: 'Precaricare le immagini',
  configMenuPosition: 'Posizione di menu',
  configMiscellaneous: 'Altro',
  configPageFlipAnimation: 'Effetto sfoglia pagine',
  configPageFlipSound: 'Rumore sfoglia pagine',
  configPageMarkerWidth: 'Larghezza segnapagina (px)',
  configPrimaryBackgroundColor: 'Colore sfondo 1',
  configPrimaryForegroundColor: 'Colore testo 1',
  configSecondaryBackgroundColor: 'Colore sfondo 2',
  configSecondaryForegroundColor: 'Colore testo 2',
  configShop: 'Shop',
  configShopArticleRegExp: 'Codice articolo Regex',
  configShopEndpoint: 'Endpoint',
  configSidePanelPosition: 'Posizione del pannello laterale',
  configToolbarPosition: 'Posizione toolbar',
  configTracking: 'Tracking',
  configTransparency: 'Trasparenza',
  configVat: 'IVA (%)',
  configVatIncluded: 'IVA inclusa',

  download: 'Scarica',
  downloadAllPages: 'Tutte le pagine',
  downloadPages: 'Pagine',
  downloadPagesPlaceholder: 'ad es. 1-5, 8, 11-13',
  downloadSelectedPages: 'Pagine selezionate',

  editorInfoSelect: 'Disegnare un rettangolo per selezionare gli hotspot all\'interno.',
  editorInfoDrawRect: 'Cliccare con il pulsante sinistro del mouse per iniziare a disegnare un hotspot rettangolare e rilasciarlo per completare l\'hotspot.',
  editorInfoDrawCircle: 'Cliccare con il pulsante sinistro del mouse per iniziare a disegnare un hotspot circolare e rilasciarlo per completare l\'hotspot.',
  editorInfoDrawEllipse: 'Cliccare con il pulsante sinistro del mouse per iniziare a disegnare un hotspot ellittico e rilasciarlo per completare l\'hotspot.',
  editorInfoDrawPolygon1: 'Cliccare con il pulsante sinistro del mouse per disegnare un nuovo hotspot poligonale o per aggiungere un punto al poligono.',
  editorInfoDrawPolygon2: 'Per completare il poligono, cliccare con il pulsante destro del mouse o premere <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⏎ Invio<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw1: 'Durante il disegno di un hotspot, premere <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Esc<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> per annullare.',
  editorInfoDraw2: 'Durante il disegno di un hotspot, è possibile tenere premuto il tasto <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⇧ Maiusc<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> per allinearlo alla griglia.',
  editorInfoDraw3: 'Fare clic su un hotspot per selezionarlo.<x id="LINE_BREAK" ctype="lb" equiv-text="&lt;br/&gt;"/>È possibile selezionare più hotspot tenendo premuto <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> e tutti gli hotspot premendo <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>A<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw4: 'È possibile copiare gli hotspot selezionati con <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>C<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> e incollarli con <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>V<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw5: 'Premere <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Canc<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> per eliminare gli hotspot selezionati.',
  editorInfoDelete: 'Fare clic su un hotspot per eliminarlo.',

  editorGrid: 'Griglia',
  editorGridColor: 'Colore griglia',
  editorGridSize: 'Dimensioni griglia (px)',
  editorGridVisibility: 'Mostra griglia',
  editorGridSnapToGrid: 'Allineamento alla griglia',

  editorTools: 'Strumenti',
  editorToolDraw: 'Disegna',
  editorToolSelect: 'Seleziona',
  editorToolDelete: 'Cancella',

  feedback: 'Feedback',
  feedbackAdditionalInfo: 'Dicci qualcosa in più (facoltativo)',
  feedbackCategoryGeneral: 'Generale',
  feedbackCategoryUi: 'Interfaccia utente',
  feedbackConfirmation: 'Grazie per averci aiutato a migliorare page2flip!',
  feedbackEmailAddress: 'Il tuo indirizzo e-mail (facoltativo)',
  feedbackError: 'Impossibile inviare il feedback. Riprova più tardi.',
  feedbackSelectCategory: 'Seleziona una categoria',

  fullscreen: 'A tutto schermo',

  help: 'Guida',
  helpGestureMenu: 'Scorri verso il basso per aprire il menu e scorri verso l\'alto per chiuderlo.',
  helpGestureNav: 'Scorri verso destra o sinistra per navigare tra le pagine.',
  helpGestureZoom: 'Tocca due volte lo schermo per ingrandire la visualizzazione.',
  helpKeyboardShortcuts: 'Shortcut',
  helpNavigation: 'Navigazione',
  helpPanelsAndDialogs: 'Pannelli e dialoghi',
  helpZoom: 'Zoom',

  home: 'Avvio',
  homeIntro: 'Trascinare un file PDF nella finestra o fare clic sul pulsante per creare una nuova pubblicazione.',
  homeLetsRumble: 'Cominciamo!',
  homeRequirements: 'Requisiti per il tuo PDF',
  homeWelcome: 'Benvenuti sulle nuove page2flip!',

  hotspots: 'Hotspot',
  hotspotCount: '<x id="INTERPOLATION" equiv-text="{{editor.hotspots.length}}"/> Hotspot',
  hotspotCountSelected: '<x id="INTERPOLATION" equiv-text="{{editor.selectedHotspots.length}}"/> Hotspot selezionati',
  hotspotCountCopied: '<x id="INTERPOLATION" equiv-text="{{editor.copiedHotspots.length}}"/> Hotspot negli Appunti',

  hotspotActionTypeArticle: 'Articolo',
  hotspotActionTypeAudio: 'Audio',
  hotspotActionTypeExternal: 'Collegamento esterno',
  hotspotActionTypeFeature: 'Feature',
  hotspotActionTypeGallery: 'Galleria',
  hotspotActionTypeIframe: 'iFrame',
  hotspotActionTypeImage: 'Immagine',
  hotspotActionTypeInfo: 'Informazioni',
  hotspotActionTypeInternal: 'Collegamento interno',
  hotspotActionTypeMulti: 'vari',
  hotspotActionTypeNone: 'Selezionare',
  hotspotActionTypeVideo: 'Video',

  hotspotDisplayTypeBorder: 'Cornice',
  hotspotDisplayTypeBox: 'Box',
  hotspotDisplayTypeHidden: 'Nascosto',
  hotspotDisplayTypeHover: 'Posizionare',
  hotspotDisplayTypeIcon: 'Icona',
  hotspotDisplayTypeInline: 'Inline',
  hotspotDisplayTypeMulti: 'vari',
  hotspotDisplayTypeNone: 'Selezionare',

  hotspotProperties: 'Proprietà hotspot',
  hotspotPropertyType: 'Tipo',
  hotspotPropertyActionInternal: 'Numero di pagina',
  hotspotPropertyActionExternal: 'URL',
  hotspotPropertyActionVideo: 'URL (MP4, WebM, Ogg, YouTube, Vimeo, Dailymotion)',
  hotspotPropertyActionAudio: 'URL (MP3, WAV, Ogg)',
  hotspotPropertyActionArticle: 'Codice articolo',
  hotspotPropertyActionDefault: 'Azione',
  hotspotPropertyTitle: 'Titolo',
  hotspotPropertyDescription: 'Descrizione',
  hotspotPropertyDisplayType: 'Tipo di rappresentazione',
  hotspotPropertyColor: 'Colore',
  hotspotPropertyAutoplay: 'Riproduzione automatica',
  hotspotPropertyPrice: 'Prezzo',
  hotspotPropertyPosition: 'Posizione',
  hotspotPropertyPositionX: 'X (px)',
  hotspotPropertyPositionY: 'Y (px)',
  hotspotPropertyDimensions: 'Dimensioni',
  hotspotPropertyDimensionWidth: 'Larghezza (px)',
  hotspotPropertyDimensionHeight: 'Altezza (px)',

  hotspotShapes: 'Forme',
  hotspotShapeRect: 'Rettangolo',
  hotspotShapeCircle: 'Cerchio',
  hotspotShapeEllipse: 'Ellisse',
  hotspotShapePolygon: 'Poligono',

  selectHotspotDisplayTypeError: 'Seleziona un tipo di rappresentazione',
  selectHotspotTypeError: 'Seleziona un tipo',

  info: 'Informazioni',
  infoCopyright1: 'Copyright © 2021 wissenswerft GmbH',
  infoCopyright2: 'page2flip è un prodotto di wissenswerft GmbH.',
  infoTechnicalQuestions: 'Per eventuali domande tecniche, non esitate a contattarci attraverso <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>support@page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  infoVisitUs: 'Visitate il nostro sito all’indirizzo <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>www.page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/> o <x id="START_LINK_1" ctype="x-a" equiv-text="&lt;a&gt;"/>www.wissenswerft.net<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',

  legal: 'Note legali',
  legalImprint: 'Colophon',
  legalPrivacyPolicy: 'Informativa sulla privacy',
  legalTermsOfService: 'Condizioni di utilizzo',

  loading: 'Caricamento in corso...',
  loadingPublication: 'Carica pubblicazione...',
  loadingPreview: 'Carica anteprima...',

  loginInstance: 'Istanza space.one',
  loginUsername: 'Nome utente',
  loginPassword: 'Password',
  loginStayLoggedIn: 'Rimani connesso',

  menu: 'Menu',

  navigationFirstPage: 'Prima pagina',
  navigationLastPage: 'Ultima pagina',
  navigationNextPage: 'Pagina successiva',
  navigationPage: 'Pagina',
  navigationPageBack: 'Indietro pagina',
  navigationPreviousPage: 'Pagina precedente',

  notes: 'Appunti',
  notesNone: 'Non hai ancora appunti. Aprire la panoramica della pagina e fare clic sull\'icona <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>comment<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> per aggiungere appunti a una pagina.',
  notesPlaceholder: 'I tuoi appunti',
  notesRemoved: 'Appunti rimossi per la pagina {{page}}.',
  notesSaved: 'Appunti salvati per la pagina {{page}}.',
  notesTitle: 'Appunti per la pagina <x id="INTERPOLATION" equiv-text="{{pageNumber}}"/>',

  offlineAvailability: 'Disponibilità offline (solo app)',

  pageComparison: 'Confronto pagine',
  pageComparisonError: 'Inserire un numero di pagina valido.',
  pageComparisonLeftPage: 'Pagina di sinistra',
  pageComparisonRightPage: 'Pagina di destra',
  pageComparisonWarning: 'Il confronto delle pagine non è disponibile quando l\'effetto sfoglia pagine è abilitato.',

  pageFlipAnimationHint: 'L\'effetto sfoglia pagine è disponibile solo nella vista desktop.',
  pageFlipAnimationWarning: 'L\'effetto sfoglia pagine non è disponibile quando è abilitato il confronto delle pagine.',

  pageOverview: 'Panoramica pagina',
  leftPage: 'Pagina di sinistra',
  rightPage: 'Pagina di destra',

  pageMarkers: 'Segnapagina',
  pageMarkerColor: 'Colore marcatore',
  pageMarkerLabel: 'Elemento identificatore',
  pageMarkerLabelColor: 'Colore testo',
  pageMarkerNew: 'Nuovo segnapagina',
  pageMarkerPage: 'Pagina',
  pageMarkerWarning: 'I segnapagina non sono disponibili quando è abilitato l\'effetto sfoglia pagine.',

  paginatorItemsPerPageLabel: 'Voci per pagina:',
  paginatorNextPageLabel: 'Pagina successiva',
  paginatorPreviousPageLabel: 'Pagina precedente',
  paginatorFirstPageLabel: 'Prima pagina',
  paginatorLastPageLabel: 'Ultima pagina',
  paginatorRangeOfLabel: 'da',

  positionBottom: 'giù',
  positionLeft: 'sinistra',
  positionRight: 'destra',
  positionTop: 'su',

  publication: 'Pubblicazione',
  publications: 'Pubblicazioni',
  publicationsLatest: 'Le tue pubblicazioni più recenti',
  publicationAuthor: 'Autore',
  publicationCover: 'Copertina',
  publicationDateCreated: 'Creato',
  publicationDateModified: 'Modificato',
  publicationDatePublished: 'Pubblicato',
  publicationDelete: 'Elimina pubblicazione',
  publicationDeleteMessage: 'Sei sicuro di voler eliminare questa pubblicazione? Questa operazione non potrà essere annullata.',
  publicationDetails: 'Dettagli',
  publicationDetailsEdit: 'Modifica dettagli',
  publicationDescription: 'Descrizione',
  publicationHotspots: 'Hotspot',
  publicationKeywords: 'Parole chiave',
  publicationLanguage: 'Lingua',
  publicationPages: 'Pagine',
  publicationTitle: 'Titolo',

  releaseApp: 'App',
  releaseDownload: 'Download',
  releaseOnline: 'Online',
  releasePreview: 'Anteprima',

  search: 'Cerca',
  searchClear: 'Elimina il termine di ricerca',
  searchFoundMatches: '<x id="INTERPOLATION" equiv-text="{{searchResults.length}}"/> pagine trovate.',
  searchNoMatches: 'Nessuna pagina trovata. Immettere almeno 3 caratteri.',
  searchMatches: '<x id="INTERPOLATION" equiv-text="{{searchResult.matches}}"/> risultati',
  searchPlaceholder: 'Cosa stai cercando?',

  settings: 'Impostazioni',

  share: 'Condividi',
  shareCurrentPage: 'Condividi la pagina corrente',
  shareSocialMedia: 'Social Media',
  shareUrl: 'URL',

  shopAdditionalInfo: 'Ulteriori informazioni ✎',
  shopItemNumber: 'Cod. art.',
  shopVatExcluded: 'IVA {{vat}}% escl.',
  shopVatIncluded: 'IVA {{vat}}% incl.',

  shoppingCart: 'Carrello',
  shoppingCartAdd: 'Aggiungi al carrello',
  shoppingCartAdded: '{{title}} aggiunto al carrello.',
  shoppingCartClear: 'Svuota carrello',
  shoppingCartCleared: 'Carrello svuotato.',
  shoppingCartEmpty: 'Non hai ancora articoli nel carrello.',
  shoppingCartPayNow: 'Paga ora',
  shoppingCartRemoved: '{{title}} rimosso dal carrello.',
  shoppingCartSum: 'Somma',

  tableOfContents: 'Distinta contenuti',
  tableOfContentsToggle: 'Espandi/riduci tutte le voci',

  uploaderConfirm: 'Vuoi caricare questo file?',
  uploaderErrorFileCount: 'È possibile caricare un solo file PDF alla volta.',
  uploaderErrorFileType: 'È possibile caricare solo file PDF.',
  uploaderFileDate: 'Data di modifica: <x id="INTERPOLATION" equiv-text="{{file.lastModified | date:dateFormat}}"/>',
  uploaderFileDrop: 'Inserire il file PDF qui.',
  uploaderFileSize: 'Dimensione del file: <x id="INTERPOLATION" equiv-text="{{file.size / 1024 / 1024 | number}}"/> MB',
  uploaderUploading: 'Attendi mentre carichiamo il tuo file.',

  viewerPreview: 'Anteprima',
  viewerPreviewDesktop: 'Desktop',
  viewerPreviewPhone: 'Smartphone',
  viewerPreviewTablet: 'Tablet',
  viewerPreviewWarning: 'L\'anteprima potrebbe differire dall\'aspetto effettivo.',

  watchList: 'Promemoria',
  watchListAdd: 'Aggiungi al promemoria',
  watchListAdded: '{{title}} aggiunto al promemoria.',
  watchListClear: 'Elimina promemoria',
  watchListCleared: 'Promemoria eliminato.',
  watchListEmpty: 'Non hai ancora elementi nel tuo promemoria.',
  watchListExists: '{{title}} è già nel promemoria.',
  watchListRemoved: '{{title}} rimosso/i dal promemoria.',

  wizardAnalysis: 'Valutazione',
  wizardAnalysisDescription: 'Statistiche e approfondimenti',
  wizardDocument: 'Documento',
  wizardDocumentDescription: 'Panoramica e metadati',
  wizardHotspots: 'Hotspot',
  wizardHotspotsDescription: 'Crea e modifica',
  wizardPublication: 'Pubblicazione',
  wizardPublicationDescription: 'Pubblica e condividi',
  wizardViewer: 'Viewer',
  wizardViewerDescription: 'Configurazione e anteprima',

  zoom: 'Zoom',
  zoomError: 'Sfiora due volte lo schermo per eseguire lo zoom.',
  zoomIn: 'Ingrandisci zoom',
  zoomJumpBottom: 'Salta in basso',
  zoomJumpLeft: 'Salta a sinistra',
  zoomJumpRight: 'Salta a destra',
  zoomJumpTop: 'Saltare in alto',
  zoomMoveDown: 'Sposta in basso',
  zoomMoveLeft: 'Sposta a sinistra',
  zoomMoveRight: 'Sposta a destra',
  zoomMoveTop: 'Sposta in alto',
  zoomOut: 'Riduci zoom',
  zoomReset: 'Reimposta zoom',

  // vw-workshop-equipment specific translations

  vw_actionCreateCatalog: 'Crea catalogo',
  vw_actionCreateNewCatalog: 'Crea un nuovo catalogo',
  vw_actionReset: 'Inizia dal davanti',
  vw_actionDownloadCatalog: 'Scarica il catalogo',
  vw_catalogLanguage: 'Lingua del catalogo',
  vw_catalogPreview: 'Anteprima del catalogo',
  vw_imprint: 'Colophon',
  vw_intro1: 'Benvenuto nel Creator del catalogo di vendita delle attrezzature per officine Volkswagen AG. Qui puoi facilmente creare il tuo catalogo specifico per il mercato in pochi passaggi. Ti consigliamo di utilizzare i browser Edge, Firefox o Chrome.',
  vw_intro2: 'È semplicissimo:',
  vw_intro3: 'Dopo circa 30 minuti, il processo di creazione si conclude ed è possibile sfogliare il catalogo, scaricarlo come un pacchetto zip o riavviare il processo.',
  vw_intro4: 'Per domande sul catalogo, desideri o idee per il miglioramento, non vediamo l\'ora di ricevere il tuo feedback al seguente indirizzo: <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Workshop.Equipment@volkswagen.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  vw_intro_list1: 'Scarica il modello di listino prezzi sul tuo PC. Si tratta di un file CSV con la gamma completa di prodotti inclusi nel catalogo, le specifiche PCR (prezzo di costo rivenditore) e PC (prezzo consigliato) conformi alla strategia e gli standard UE per i marchi aziendali. I prodotti con fatturazione diretta possono essere trovati nell\'elenco con l\'indicazione "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>". <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Scarica modello di listino prezzi (stato: prezzi 01.09.2019, standard 01.10.2018)<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>',
  vw_intro_list2: 'Apri il file CSV nel programma Excel.',
  vw_intro_list3: 'Inserisci i prezzi specifici del mercato nelle colonne PCR (prezzo di costo rivenditore) e PC (prezzo consigliato). Se non si desidera specificare un prezzo o non si offre un prodotto nel proprio mercato, è sufficiente immettere "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" o "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>not available<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" nel campo del prezzo.',
  vw_intro_list4: 'Aggiungi le tue informazioni sullo standard nelle colonne VW, Audi, SEAT, SKODA, VWN e Porsche. Inserisci uno "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>0<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" se il prodotto non rientra nello standard nella marca pertinente, o un "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>1<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" se fa parte dello standard.',
  vw_intro_list5: 'Non modificare le voci nelle colonne “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Langtext (Technik)<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” e “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Teilenummer<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/> per garantire un perfetto funzionamento. Inoltre, non eliminare o aggiungere colonne o righe e non modificare le intestazioni di colonna.',
  vw_intro_list6: 'Salva il listino prezzi come file CSV localmente sul tuo PC.',
  vw_intro_list7: 'Scarica il tuo listino prezzi utilizzando il pulsante "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Seleziona file<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" qui sotto o trascinando il file CSV fino alla casella grigia.',
  vw_intro_list8: 'Inserisci le informazioni sulla pagina del titolo: il tuo mercato, il tuo prezzo (PCR o PC) e lo stato del prezzo (data di validità delle informazioni sul prezzo).',
  vw_intro_list9: 'Seleziona la lingua del catalogo.',
  vw_intro_list10: 'Avvia il processo di creazione dal pulsante "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Crea catalogo<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>".',
  vw_legalNotice: 'Note legali',
  vw_loginError: 'Password errata.',
  vw_loginMessage: 'Accedi per continuare.',
  vw_market: 'Mercato',
  vw_priceDate: 'Stato dei prezzi',
  vw_priceList: 'Listino',
  vw_priceType: 'Determinazione del prezzo',
  vw_priceTypeDNP: 'DNP',
  vw_priceTypeRRP: 'RRP',
  vw_stateConverting: 'Creazione catalogo in corso. L\'operazione potrebbe richiedere del tempo.',
  vw_stateFailed: 'Si è verificato un errore. Si prega di riprovare.',
  vw_stateUploading: 'Caricamento dati in corso. Si prega di attendere un momento per l’inizio del processo di creazione.',
  vw_stateWaiting: 'Il catalogo è stato aggiunto alla coda.',
  vw_title: 'Workshop Equipment Catalogue Creator',
  vw_uploaderMsgClickOrDragFile: 'o il file CSV qui.',
  vw_uploaderMsgDropFile: 'Depositare il file qui.',
  vw_uploaderMsgFileCountError: 'È possibile caricare un solo file.',
  vw_uploaderMsgFileTypeError: 'È possibile caricare solo file CSV.'

};
