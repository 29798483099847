import { getTranslation } from '../locale/translation.provider';

const keysWin = {
  navigation: {
    nextPage: 'ArrowRight',
    previousPage: 'ArrowLeft',
    firstPage: 'Home',
    lastPage: 'End',
    pageBack: 'Backspace'
  },
  zoom: {
    zoomIn: '+',
    zoomOut: '-',
    resetZoom: '0',
    viewport: {
      moveUp: 'ArrowUp',
      moveRight: 'ArrowRight',
      moveDown: 'ArrowDown',
      moveLeft: 'ArrowLeft',
      jumpTop: 'Control.ArrowUp',
      jumpRight: 'Control.ArrowRight',
      jumpBottom: 'Control.ArrowDown',
      jumpLeft: 'Control.ArrowLeft'
    }
  },
  panels: {
    tableOfContents: 'Alt.1',
    search: 'Alt.2',
    pageOverview: 'Alt.3',
    bookmarks: 'Alt.4',
    notes: 'Alt.5',
    watchList: 'Alt.6',
    shoppingCart: 'Alt.7'
  },
  dialogs: {
    share: 'Alt.8',
    download: 'Alt.9',
    pageComparison: 'Alt.0',
    help: 'Shift.h',
    info: 'Shift.i',
    legal: 'Shift.l',
    feedback: 'Shift.f',
    config: 'Shift.c'
  },
  close: 'Escape'
};

const keysMac = {
  navigation: {
    nextPage: 'ArrowRight',
    previousPage: 'ArrowLeft',
    firstPage: 'Home',
    lastPage: 'End',
    pageBack: 'Backspace'
  },
  zoom: {
    zoomIn: '+',
    zoomOut: '-',
    resetZoom: '0',
    viewport: {
      moveUp: 'ArrowUp',
      moveRight: 'ArrowRight',
      moveDown: 'ArrowDown',
      moveLeft: 'ArrowLeft',
      jumpTop: 'Meta.ArrowUp',
      jumpRight: 'Meta.ArrowRight',
      jumpBottom: 'Meta.ArrowDown',
      jumpLeft: 'Meta.ArrowLeft'
    }
  },
  panels: {
    tableOfContents: 'Control.1',
    search: 'Control.2',
    pageOverview: 'Control.3',
    bookmarks: 'Control.4',
    notes: 'Control.5',
    watchList: 'Control.6',
    shoppingCart: 'Control.7'
  },
  dialogs: {
    share: 'Control.8',
    download: 'Control.9',
    pageComparison: 'Control.0',
    help: 'Shift.h',
    info: 'Shift.i',
    legal: 'Shift.l',
    feedback: 'Shift.f',
    config: 'Shift.c'
  },
  close: 'Escape'
};

export const keys = window.navigator.platform.toLowerCase().includes('win') ? keysWin : keysMac;

const symbolsWin = {
  'ArrowUp': '↑',
  'ArrowRight': '→',
  'ArrowDown': '↓',
  'ArrowLeft': '←',
  'Home': getTranslation('_keyHome') || 'Home',
  'End': getTranslation('_keyEnd') || 'End',
  'Backspace': '⌫',
  'Shift': getTranslation('_keyShift') || '⇧ Shift',
  'Alt': getTranslation('_keyAlt') || 'Alt',
  'Control': getTranslation('_keyControl') || '✲ Ctrl',
  'Escape': 'Esc'
};

const symbolsMac = {
  'ArrowUp': '↑',
  'ArrowRight': '→',
  'ArrowDown': '↓',
  'ArrowLeft': '←',
  'Home': '↖',
  'End': '↘',
  'Backspace': '⟵',
  'Shift': '⇧',
  'Alt': '⎇ alt',
  'Meta': '⌘ cmd',
  'Control': 'ctrl',
  'Escape': 'esc'
};

export const symbols = window.navigator.platform.toLowerCase().includes('win') ? symbolsWin : symbolsMac;
