import { Component, Inject, ViewContainerRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

import { DataService, WINDOW } from '@page2flip/core';
import { AuthenticationService } from './core/services/authentication.service';
import { SpaceOneInstanceService } from './core/services/space-one-instance.service';

/**
 * Component that represents the entry point of the application.
 */
@Component({
  selector: 'p2f-creator',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  /** Whether or not the `content` class should be added to the main container. */
  content: boolean;

  /** Whether or not the user visits from a Mac. */
  readonly isMac: boolean = this.window.navigator.platform.toLowerCase().includes('mac');

  /**
   * Constructor of the component.
   *
   * @param window        DI Token for the Window object.
   * @param data          Service to fetch data from the backend.
   * @param iconRegistry  Service to register and display icons used by the `<mat-icon>` component.
   * @param instance      Service to set the space.one instance.
   * @param sanitizer     Sanitizes a value for use in the given SecurityContext.
   * @param auth          Service to authenticate the user.
   * @param router        Provides the navigation and url manipulation capabilities.
   * @param vcRef         Represents a container where one or more Views can be attached.
   */
  constructor(@Inject(WINDOW) private window: any,
              private data: DataService,
              private iconRegistry: MatIconRegistry,
              private instance: SpaceOneInstanceService,
              private sanitizer: DomSanitizer,
              public auth: AuthenticationService,
              public router: Router,
              public vcRef: ViewContainerRef /* for color picker */) {
    iconRegistry.addSvgIcon('home', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-home.svg'));
    iconRegistry.addSvgIcon('feedback', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-feedback.svg'));
    iconRegistry.addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl('assets/images/icon-settings.svg'));

    router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.content = this.router.url !== '/home' && !this.router.url.startsWith('/wizard'); // FIXME: use host-context() in children components css
      }
    });
  }

  /**
   * Logs the current user out.
   */
  logout() {
    this.auth.logout().subscribe(() => {
      this.auth.currentUser = undefined;
      this.router.navigate(['/login']);
    })
  }

}
