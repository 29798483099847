// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  spaceOneApiUrl: "https://page2flip.devdock.space.one/api",
  spaceOneInstance: "page2flip.devdock.space.one",
  spaceOneScope: "demo",
  feedbackApiUrl: 'https://portal.page2flip.com/api/scope/stat/items/feedback',
  feedbackApiKey: 'mruZFQcdn9ja2rncQ82BatgMTSWEttnn'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
