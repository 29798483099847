import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostBinding, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { DataService, UploaderService } from '@page2flip/core';
import { Publication } from '@page2flip/core/common';
import { Subscription } from 'rxjs';

import { getTranslation } from '../../../locale/translation.provider';

/**
 * Component that represents the uploader overlay.
 */
@Component({
  selector: 'p2f-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: [ './uploader.component.css' ]
})
export class UploaderComponent implements OnInit, OnDestroy {
  /** Whether or not the user is dragging a file into the window. */
  @Output() updatePublications: EventEmitter<Publication> = new EventEmitter();

  @HostBinding('class.file-dragging') dragging: boolean;

  /** Whether or not there is a problem with the user PDF file. */
  @HostBinding('class.file-error') error: string;

  /** User PDF file. */
  @HostBinding('class.file-selected') file: File;

  /** Whether or not the user is uploading a PDF file. */
  @HostBinding('class.file-uploading') uploading: boolean;

  /** Subscriptions of the component. */
  private readonly subscriptions: Subscription[] = [];

  public uploadProgress: Number = 0;

  /** Date format. */
  readonly dateFormat: string =
    getTranslation('_mediumDateFormat') || 'MMMM d, yyyy, HH:mm';

  /**
   * Constructor of the component.
   *
   * @param data      Service to fetch data from the backend.
   * @param element   Wrapper around a native element inside of a View.
   * @param renderer  Service that provides a low-level interface for modifying the UI.
   * @param uploader  Service for uploading files to the backend.
   */
  constructor(
    private data: DataService,
    private element: ElementRef,
    private renderer: Renderer2,
    private uploader: UploaderService
  ) { }

  /**
   * Handles the component subscriptions.
   */
  ngOnInit() {
    this.renderer.listen('document', 'dragenter', (event: DragEvent) => {
      this.preventDefaults(event);
      this.dragging = true;
      this.error = undefined;
      this.file = undefined;
    });

    this.renderer.listen('document', 'dragover', (event: DragEvent) => {
      this.preventDefaults(event);
      this.dragging = true;
      this.error = undefined;
      this.file = undefined;
    });

    this.renderer.listen(
      this.element.nativeElement,
      'dragleave',
      (event: DragEvent) => {
        this.preventDefaults(event);
        this.dragging = false;
      }
    );

    this.renderer.listen('document', 'drop', (event: DragEvent) => {
      this.preventDefaults(event);
      this.dragging = false;
      this.onFileDrop(event);
    });

    this.subscriptions[
      this.subscriptions.length
    ] = this.uploader.data().subscribe((file: File) => (this.file = file));
    this.subscriptions[
      this.subscriptions.length
    ] = this.uploader
      .error()
      .subscribe((error: HttpErrorResponse) => (this.error = error.error));
  }

  /**
   * Destroys the component subscriptions.
   */
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Prevents event defaults and propagation.
   *
   * @param event Drag event.
   */
  private preventDefaults(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Handles dropped files.
   *
   * @param event File drop event.
   */
  onFileDrop(event: DragEvent | any) {
    // more than one file dragged
    if (event.dataTransfer && event.dataTransfer.files.length > 1) {
      this.error =
        getTranslation('uploaderErrorFileCount') ||
        'You can only upload one PDF file at once.';
      return;
    }

    const file: File = event.dataTransfer.files[ 0 ];
    const parts: string[] = file.name.split('.');
    const type: string = parts[ parts.length - 1 ].toLowerCase();

    // wrong file type
    if (type !== 'pdf') {
      this.error =
        getTranslation('uploaderErrorFileType') ||
        'You can only upload PDF files.';
      return;
    }

    this.file = file;
  }

  /**
   * Resets the uploader.
   */
  reset() {
    this.dragging = undefined;
    this.error = undefined;
    this.file = undefined;
    this.uploading = undefined;

    this.uploader.resetFileInput();
  }

  /**
   * Uploads a file.
   */
  upload() {
    this.uploadProgress = 50;
    this.uploader
      .uploadFile(this.file)
      .subscribe(() => {
        // will be removed after uploading progress from the header
        this.uploadProgress = 70;
        setTimeout(() => {
          this.uploadProgress = 100;
        }, 1000);
        // loadPublication should load assets first then get the publications,
        // then the setTimoute can be removed
        setTimeout(() => {
          this.data.loadPublications().subscribe(res => { this.updatePublications.emit(res); });
          this.reset();
        }, 2000);
      });
    this.uploading = true;
    this.file = undefined;
  }
}
